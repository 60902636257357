import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const category = [
  {
    name: 'Soundalikes',
    value: 'category_soundalikes',
    color: '#ff45ed'
  },
  {
    name: 'Amounts',
    value: 'category_amounts',
    color: '#3e48ff'
  },
  {
    name: 'Anatomy',
    value: 'category_anatomy',
    color: '#00aaff'
  },
  {
    name: 'Animals',
    value: 'category_animals',
    color: '#39b70f'
  },
  {
    name: 'Architecture',
    value: 'category_architecture',
    color: '#ff4daf'
  },
  {
    name: 'Attire',
    value: 'category_attire',
    color: '#ff3e3e'
  },
  {
    name: 'Landscapes',
    value: 'category_landscapes',
    color: '#36ce36'
  },
  {
    name: 'Random',
    icon: 'category_random',
    value: undefined,
    color: '#b20059'
  },
  {
    name: 'Dining',
    value: 'category_dining',
    color: '#efd203'
  },
  {
    name: 'Feelings',
    value: 'category_feelings',
    color: '#00baa3'
  },
  {
    name: 'Health',
    value: 'category_health',
    color: '#633eff'
  },
  {
    name: 'Home',
    value: 'category_home',
    color: '#ff5027',
    disabled: true
  },
  {
    name: 'Law',
    value: 'category_law',
    color: '#a9d100',
    disabled: true
  },
  {
    name: 'People',
    value: 'category_people',
    color: '#1569ff',
    disabled: true
  },
  {
    name: 'Sports',
    value: 'category_sports',
    color: '#ff6333',
    disabled: true
  },
  {
    name: 'Tech',
    value: 'category_tech',
    color: '#009fef',
    disabled: true
  },
  {
    name: 'Travel',
    value: 'category_travel',
    color: '#b331ff',
    disabled: true
  },
  {
    name: 'Custom(AI-generated)',
    value: 'category_custom',
    color: '#ff9d00',
    disabled: true
  },
  {
    name: 'Work',
    value: 'category_work',
    color: '#ff9d00',
    disabled: true
  }
]

export const fetchData = createAsyncThunk(
  'user/fetchUserData',
  async () => {
    try {
      const response = await axios.get(baseURL + '/v1/all-games');
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const allGamesState = {
  allGameData: null,
  loading: false,
  error: null,
  allGameCount: null,
  maxGamePlayedInCatagoryWithCount: {
    catagory: '',
    count: 0,
    categoryName: ''
  }
}

export const allGamesSlice = createSlice({
  name: 'users',
  initialState: allGamesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.allGameData = action.payload;
        state.allGameCount = action.payload.users.length

        let maxCategoryCount = 0;
        let maxCategoryName = '';

        category.map((catagoryValue, index) => {
          let count = 0;
          const categoryGames = action.payload.users.filter(game => game.category === catagoryValue.value);
          count = categoryGames.length;

          if (count > maxCategoryCount) {
            maxCategoryCount = count;
            maxCategoryName = catagoryValue.value;
            state.maxGamePlayedInCatagoryWithCount.catagory = maxCategoryName
            state.maxGamePlayedInCatagoryWithCount.count = maxCategoryCount
            state.maxGamePlayedInCatagoryWithCount.categoryName = catagoryValue.name
          }
        });

      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
})

export const { } = allGamesSlice.actions

export default allGamesSlice.reducer