/* eslint-disable */
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;


export default {
  login: async function (person) {
    try {
      const response = await axios.post(baseURL + '/v1/auth/login', person);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },


  languages: async function () {
    try {
      const response = await axios.get(baseURL + '/v1/admin/languages');
      return response.data;
    } catch (error) {

      console.error('An error occurreeed', error);
      throw error;
    }
  },

  // Getusers: async function () {
  //   try {
  //     const response = await axios.get(baseURL + `/v1/users`);
  //     return await response.data;
  //   } catch (error) {

  //     console.error('An error occurreeed', error);
  //     throw error;
  //   }
  // },

  //get single user
  GetUserById: async function (id) {
    try {
      const response = await axios.get(baseURL + `/v1/user/${id}`);
      return await response.data;
    } catch (error) {

      console.error('An error occurreeed', error);
      throw error;
    }
  },

  Getgames: async function () {
    try {
      const response = await axios.get(baseURL + `/v1/all-games`);
      return await response.data;
    } catch (error) {

      console.error('An error occurreeed', error);
      throw error;
    }
  },

  GetgamesById: async function (id) {
    try {
      const response = await axios.get(baseURL + `/v1/allgame/${id}`);
      return await response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  Getwords: async function () {
    try {
      const response = await axios.get(baseURL + `/v1/allwords`);
      return await response.data;
    } catch (error) {

      console.error('An error occurreeed', error);
      throw error;
    }
  },

  addWord: async function (data) {
    try {
      const response = await axios.post(baseURL + '/v1/admin/add-word', data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  removeword: async function (data) {
    try {
      const response = await axios.post(baseURL + '/v1/admin/remove-word', data);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  addhomoWord: async function (data) {
    try {
      const response = await axios.post(baseURL + '/v1/admin/add-homonym', data);
      return response.data;

    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  removehomoWord: async function (data) {
    try {
      const response = await axios.post(baseURL + '/v1/admin/remove-homonym', data);
      return response.data;

    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  totalGames: async function () {
    try {
      const response = await axios.get(baseURL + '/v1/admin/totalgames');

      return response.data;

    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  totalword: async function () {
    try {
      const response = await axios.get(baseURL + '/v1/total-word');
      console.log('response.data.', response.data)

      return response.data;

    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  deleteWord: async function (word) {
    try {
      const response = await axios.delete(baseURL + '/v1/delete-words', {
        data: { word },
      });
      console.log('response.data.', response.data)

      return response.data;

    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  editWords: async function (words) {
    try {
      const response = await axios.put(baseURL + '/v1/edit-words', {
        data: { words },
      });
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  gameWithSelectedDate: async function (data) {
    try {
      const response = await axios.get(baseURL + `/v1/games-with-selected-date/${data}`);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  getVoucherSettings: async function (id) {
    try {
      const response = await axios.get(baseURL + `/v1/get-voucher-setting/${id}`);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },

  UpdateVoucherSettings: async function (data) {
    // console.log('data at api call',data);
    try {
      const response = await axios.put(baseURL + `/v1/update-voucher-setting`, data);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },
  getSelectedTopic: async function () {
    try {
      const response = await axios.get(baseURL + `/v1/get-selected-topic`);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },
  updateSelectedTopic: async function (data) {
    try {
      const response = await axios.put(baseURL + `/v1/update-selected-topic`, data);
      return response.data;
    } catch (error) {
      console.error('An error occurreeed', error);
      throw error;
    }
  },
};