import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import './Getusers.css'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import { Calendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { format } from 'date-fns'
import moment from 'moment'

import '../Admin.css'
import {
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
} from '@coreui/react'
import { Box, Skeleton } from '@mui/material'
import { useGetGamesQuery } from 'src/components/APISlices/userapiSlice'
import Services from 'src/services/Services'

const User = () => {
  const [singleUser, setSingleUser] = useState([])
  const [page, setPage] = useState(0)
  const [pageTwo, setPageTwo] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [gameResult, setGameResults] = useState([])
  let [showModal, setShowModal] = useState(false)
  let [loading, setLoading] = useState(false)
  let [loadingMsg, setLoadingmsg] = useState(false)
  let [created, setCreated] = useState('')

  const [visible, setVisible] = useState(false)
  const [visibleTwo, setVisibleTwo] = useState(false)

  const [formatedDate, setFormatedDate] = useState()
  const [formatedToDate, setFormatedToDate] = useState()

  const [selectedVar, setSelectedVar] = useState('Daily')

  const wrapperRef = useRef(null)

  const [isClickedFromDate, setIsClickedFromDate] = useState(false)
  const [isClickedToDate, setIsClickedToDate] = useState(false)

  const [selectedFilterDate, setSelectedFilterDate] = useState(new Date())
  const [selectedToFilterDate, setSelectedToFilterDate] = useState(new Date())

  const [userGameCountFilteredValue, setUserGameCountFilteredValue] = useState(null)

  const [userGamesCount, setUserGamesCount] = useState(null)
  const [userAvg, setUserAvg] = useState('0')
  const [filteredData, setFilteredData] = useState([])
  const [selectedGame, setSelectedGame] = useState('all')
  const [selectedMode, setSelectedMode] = useState('all')
  const [selectedCompletion, setSelectedCompletion] = useState('all')
  const [selectedGameResult, setSelectedGameResult] = useState({})
  const [selectedGameResultModel, setSelectedGameResultModel] = useState(false)

  useEffect(() => {
    const originalDate = new Date(selectedFilterDate)
    const formattedDate = format(originalDate, 'yyyy-MM-dd')
    setFormatedDate(formattedDate)
  }, [setSelectedFilterDate, selectedFilterDate])

  useEffect(() => {
    const originalDate = new Date(selectedToFilterDate)
    const formattedDate = format(originalDate, 'yyyy-MM-dd')
    setFormatedToDate(formattedDate)
  }, [selectedToFilterDate, setSelectedToFilterDate])

  let { id } = useParams()
  let showmsg = false
  let result = []
  let guessSum = 0
  let correctGuessSum = 0
  let location = useLocation()
  let state = location.state

  const { data: gameData, error, isLoading} = useGetGamesQuery({userId: id})
  // const { data: resultData, isLoading: resultLoading } = useGetResultsQuery()

  useEffect(() => {
    fetchData()
  }, [isLoading])

  const fetchData = async () => {
    let usersData = []
    if (isLoading === false) {
      // usersData = data.users
      usersData = gameData?.games
    } else {
      setLoading(true)
    }
    setSingleUser([usersData])
  }
  const filteredUsers = singleUser.flatMap((e) =>
    e.filter((user) => user.speaker === id || user.speller === id),
  )
  filteredUsers.map((user) => {
    let game = user.finished === true ? 'Completed' : 'Not Completed'
    let mode = user !== null && user.solo === true ? 'Solo' : 'Partnered'
    //  const formattedCategory = category.replace("category_", "");
    let topicofCustom = user?.category === 'category_custom' ? `(${user.words})` : ''
    let category = user.category
      ? user.category.replace('category_', '')
      : user?.category === undefined
      ? 'random'
      : 'NA'
    let level =
      user.difficulty === null ? <p className="text-danger mb-0">N/A</p> : user.difficulty
    let language =
      user.language === null || user.language === undefined ? (
        <p className="text-danger mb-0">Not available</p>
      ) : (
        user.language
      )
    let created = user !== null && user.created
    if (created) {
      created = created.slice(0, 10)
    }
    let id = user !== null && user._id
    let numGuesses = user !== null && user.numGuesses
    let numCorrectGuesses = user !== null && user.numCorrectGuesses
    let email = state !== null && state.email
    let username = state !== null && state.username
    result.push({
      _id: id,
      created: moment(created).format('MM-DD-YYYY'),
      language: language,
      difficulty: level,
      category: category,
      numCorrectGuesses: numCorrectGuesses,
      numGuesses: numGuesses,
      numIncorrectGuesses: numGuesses - numCorrectGuesses,
      finished: game,
      solo: mode,
      email: email,
      username: username,
      playedTime: user.playedTime !== undefined ? user.playedTime : 'NA',
      gameTimer: user.gameTimer ? user.gameTimer : 'NA',
      results: user?.results || [],
      topicofCustom: topicofCustom,
    })

    // //**** Sum of words ****/
    guessSum += user.numGuesses
    correctGuessSum += user.numCorrectGuesses
  })

  useEffect(() => {
    // Calculate average and update state
    if (guessSum !== 0) {
      const avg = (correctGuessSum / guessSum) * 100
      setUserAvg(avg.toFixed(2))
    }
  }, [correctGuessSum, guessSum])

  const columns = [
    // { id: 'username', label: 'Username', minWidth: 20, maxWidth: 30 },
    // { id: 'email', label: 'Email', minWidth: 30, maxWidth: 50 },
    { id: 'category', label: 'Category', minWidth: 30, maxWidth: 50 },
    // { id: 'gameTimer', label: 'Game Timer', minWidth: 30, maxWidth: 50 },
    // { id: 'playedTime', label: 'Played Time', minWidth: 30, maxWidth: 50 },
    { id: 'created', label: 'Created', minWidth: 60, maxWidth: 100 },
    { id: 'finished', label: 'Game', minWidth: 30, maxWidth: 50 },
    { id: 'difficulty', label: 'Difficulty', minWidth: 30, maxWidth: 50 },
    { id: 'solo', label: 'Mode', minWidth: 30, maxWidth: 50 },
    { id: 'numGuesses', label: 'NumGuesses', minWidth: 30, maxWidth: 50 },
    { id: 'numCorrectGuesses', label: 'Correct words', minWidth: 30, maxWidth: 50 },
    { id: 'numIncorrectGuesses', label: 'Incorrect words', minWidth: 30, maxWidth: 50 },
  ]

  var rows = result && result
  rows = rows.sort((a, b) => {
    // Convert the date strings to Date objects
    const dateA = new Date(a.created)
    const dateB = new Date(b.created)

    // Compare the Date objects to sort in descending order
    return dateB - dateA
  })

  Date.prototype.getISOWeek = function () {
    const date = new Date(this)
    date.setDate(date.getDate() + 4 - (date.getDay() || 7))
    const yearStart = new Date(date.getFullYear(), 0, 1)
    return Math.ceil(((date - yearStart) / 86400000 + 1) / 7)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangePageTwo = (event, newPage) => {
    setPageTwo(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const showResult = async (e) => {
    let { _id } = e
    const response = await Services.GetgamesById(_id)
    const userResult = response.game
    setGameResults(userResult.results)
    setShowModal(true)
    setCreated(e.created)
  }

  useEffect(() => {
    if (result.length <= 0) {
      setLoading(true)
    } else if (result.length === 0) {
      setLoading(false)
      setLoadingmsg(true)
    }
  }, [])

  if (gameResult === undefined) {
    showmsg = true
  } else {
    showmsg = false
  }

  const handleVoucherSettingSaveButtonClick = () => {}

  const handleStartDateClick = (clickOn) => {
    if (clickOn === 'fromDate') {
      setIsClickedFromDate(!isClickedFromDate)
    } else {
      setIsClickedToDate(!isClickedToDate)
    }
  }

  useEffect(() => {
    loadDailyData()
  }, [singleUser])

  const loadDailyData = () => {
    const dateCounts = []

    rows.forEach((item) => {
      const dateArray = item.created.split('T')[0].split('-')
      const formattedDate = `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`
      const category = item.solo === 'Solo' ? 'soloCount' : 'partneredCount'

      const existingEntry = dateCounts.find((entry) => entry.date === formattedDate)

      if (existingEntry) {
        existingEntry.count++
        existingEntry[category]++
      } else {
        dateCounts.push({
          date: formattedDate,
          count: 1,
          soloCount: item.solo === 'Solo' ? 1 : 0,
          partneredCount: item.solo === 'Solo' ? 0 : 1,
        })
      }
    })
    setUserGameCountFilteredValue(dateCounts)
  }

  const handleVarChange = (event) => {
    const selectedValue = event.target.value
    setSelectedVar(selectedValue)

    if (selectedValue === 'Daily') {
      loadDailyData()
    }

    if (selectedValue === 'Weekly') {
      const gameCounts = {}

      rows.forEach((item) => {
        const createdDate = new Date(item.created)
        const clonedCreatedDate = new Date(createdDate) // Clone the date

        clonedCreatedDate.setUTCHours(0, 0, 0, 0) // Set time to midnight in UTC

        const startDate = new Date(clonedCreatedDate)
        startDate.setDate(clonedCreatedDate.getDate() - clonedCreatedDate.getDay() + 1) // Set to Monday of the current week

        if (startDate > clonedCreatedDate) {
          startDate.setDate(startDate.getDate() - 7) // Adjust start date to the previous week
        }

        const endDate = new Date(startDate)
        endDate.setDate(startDate.getDate() + 6) // Set to Sunday of the same week
        endDate.setUTCHours(23, 59, 59, 999) // Set time to end of the day in UTC

        // Check if createdDate is between startDate and endDate
        if (createdDate >= startDate && createdDate <= endDate) {
          // const weekKey = `${startDate.toISOString().split('T')[0]} to ${endDate.toISOString().split('T')[0]}`;
          // -----------------
          // const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
          // const startDateFormatted = new Date(startDate).toLocaleDateString('en-US', options)
          // const endDateFormatted = new Date(endDate).toLocaleDateString('en-US', options)
          // -----------------
          const startDateFormatted = moment(startDate).format('MM-DD-YYYY')
          const endDateFormatted = moment(endDate).format('MM-DD-YYYY')
          const weekKey = `${startDateFormatted} to ${endDateFormatted}`

          const countObj = gameCounts[weekKey]

          if (countObj) {
            countObj.count += 1
            countObj.soloCount += item.solo === 'Solo' ? 1 : 0
            countObj.partneredCount += item.solo === 'Partnered' ? 1 : 0
          } else {
            gameCounts[weekKey] = {
              date: weekKey,
              count: 1,
              soloCount: item.solo === 'Solo' ? 1 : 0,
              partneredCount: item.solo === 'Partnered' ? 1 : 0,
            }
          }
        }
      })

      const weekCounts = Object.values(gameCounts)

      setUserGameCountFilteredValue(weekCounts)
    }

    if (selectedValue === 'Monthly') {
      const monthlyCounts = []
      const countsMap = {}

      rows.forEach((item) => {
        const createdDate = new Date(item.created)
        const startDate = new Date(createdDate)
        startDate.setDate(1) // Set to the 1st day of the month

        const monthKey = `${String(startDate.getMonth() + 1).padStart(
          2,
          '0',
        )}-${startDate.getFullYear()}`

        if (countsMap[monthKey]) {
          countsMap[monthKey].count += 1
          if (item.solo === 'Solo') {
            countsMap[monthKey].soloCount += 1
          } else if (item.solo === 'Partnered') {
            countsMap[monthKey].partneredCount += 1
          }
        } else {
          countsMap[monthKey] = {
            date: monthKey,
            count: 1,
            soloCount: item.solo === 'Solo' ? 1 : 0,
            partneredCount: item.solo === 'Partnered' ? 1 : 0,
          }
        }
      })

      // Convert the map values to an array
      monthlyCounts.push(...Object.values(countsMap))
      setUserGameCountFilteredValue(monthlyCounts)
    }
  }

  const handleApplyDateFilter = async () => {
    setIsClickedFromDate(false)
    setIsClickedToDate(false)

    if (selectedVar === 'Daily') {
      const endOfDay = moment(formatedToDate).endOf('day')

      const filteredData = userGameCountFilteredValue.filter((data) => {
        // Convert the date string to a format that moment can parse
        const currentDate = moment(data.date, 'DD/MM/YYYY')

        // Check if currentDate is a valid date before comparing
        if (!currentDate.isValid()) {
          return false
        }

        return currentDate.isBetween(formatedDate, endOfDay, null, '[]')
      })

      setUserGameCountFilteredValue(filteredData)
      // Use filteredData as needed
    }

    if (selectedVar === 'Weekly') {
      const filteredRows = rows.filter((item) => {
        const createdDate = moment(item.created)

        // Check if the createdDate is within the date range
        return createdDate.isBetween(formatedDate, formatedToDate, null, '[]')
      })

      const monthlyCounts = []
      const countsMap = {}

      filteredRows.forEach((item) => {
        const createdDate = new Date(item.created)
        const startDate = new Date(createdDate)
        startDate.setDate(1) // Set to the 1st day of the month

        const monthKey = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(
          2,
          '0',
        )}`

        if (countsMap[monthKey]) {
          countsMap[monthKey].count += 1
          if (item.solo === 'Solo') {
            countsMap[monthKey].soloCount += 1
          } else if (item.solo === 'Partnered') {
            countsMap[monthKey].partneredCount += 1
          }
        } else {
          countsMap[monthKey] = {
            date: monthKey,
            count: 1,
            soloCount: item.solo === 'Solo' ? 1 : 0,
            partneredCount: item.solo === 'Partnered' ? 1 : 0,
          }
        }
      })

      // Convert the map values to an array
      monthlyCounts.push(...Object.values(countsMap))

      // Sort the array in ascending order by date
      // monthlyCounts.sort((a, b) => a.date.localeCompare(b.date));

      setUserGameCountFilteredValue(monthlyCounts)

      // Use filteredRows as needed
    }

    if (selectedVar === 'Monthly') {
      const filteredRows = rows.filter((item) => {
        const createdDate = moment(item.created)

        // Convert formatedToDate to a moment object
        const endDate = moment(formatedToDate).endOf('day')

        // Check if the createdDate is within the date range
        return createdDate.isBetween(formatedDate, endDate, null, '[]')
      })

      const monthlyCounts = []
      const countsMap = {}

      filteredRows.forEach((item) => {
        const createdDate = new Date(item.created)
        const startDate = new Date(createdDate)
        startDate.setDate(1) // Set to the 1st day of the month

        const monthKey = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(
          2,
          '0',
        )}`

        if (countsMap[monthKey]) {
          countsMap[monthKey].count += 1
          if (item.solo === 'Solo') {
            countsMap[monthKey].soloCount += 1
          } else if (item.solo === 'Partnered') {
            countsMap[monthKey].partneredCount += 1
          }
        } else {
          countsMap[monthKey] = {
            date: monthKey,
            count: 1,
            soloCount: item.solo === 'Solo' ? 1 : 0,
            partneredCount: item.solo === 'Partnered' ? 1 : 0,
          }
        }
      })

      // Convert the map values to an array
      monthlyCounts.push(...Object.values(countsMap))

      // Sort the array in descending order by date
      // monthlyCounts.sort((a, b) => b.date.localeCompare(a.date));

      setUserGameCountFilteredValue(monthlyCounts)
    }
  }

  // const handleGameCountClick = (item) => {
  //   // setVisible(false)
  //   setVisibleTwo(true)


  //   if (item.date.includes(' to ')) {

  //     const [start, end] = item.date.split(' to ').map(dateStr => {
  //       const [day, month, year] = dateStr.split('/');
  //       const formattedDate = `${year}-${month}-${day}`;
  //       return moment(formattedDate, 'YYYY-MM-DD');
  //     });

  //     let startDate = moment(start).add(1, 'day'); // Start from the next day
  //     let endDate = moment(end).add(1, 'day'); // Adjust end date to the day after

  //     console.log('startDate', startDate.toISOString());
  //     console.log('endDate', endDate.toISOString());

  //     const filteredRows = rows.filter(row => {
  //       const rowDate = moment(row.created).startOf('day');
  //       return rowDate.isBetween(startDate.startOf('day'), endDate.startOf('day'), null, '[]');
  //     });

  //     setUserGamesCount(filteredRows);
  //   }

  //   else if (item.date.includes('/')) {
  //     const [day, month, year] = item.date.split('/');
  //     const formattedItemDate = new Date(`${year}-${month}-${day}T00:00:00Z`);

  //     const filteredRows = rows.filter(row => {
  //       const rowDate = new Date(row.created);
  //       const formattedRowDate = new Date(`${rowDate.toISOString().split('T')[0]}T00:00:00Z`);

  //       return formattedRowDate.getTime() === formattedItemDate.getTime();
  //     });

  //     setUserGamesCount(filteredRows)
  //   } else if (item.date.includes('-')) {
  //   }
  // }

  useEffect(() => {
    if (
      rows.length > 0 &&
      filteredData.length == 0 &&
      selectedGame == 'all' &&
      selectedMode == 'all' &&
      selectedCompletion == 'all'
    ) {
      setFilteredData(rows)
    }
  }, [rows])
useEffect(()=>{
  setPage(0)
  let filtered = rows
  if (selectedGame != 'all') {
    filtered = filtered.filter((row) => row.category == selectedGame)
  }
  if (selectedMode !== 'all') {
    filtered = filtered.filter((row) => row.solo == selectedMode)
  }

  if (selectedCompletion !== 'all') {
    filtered = filtered.filter((row) => row.finished == selectedCompletion)
  }
  setFilteredData(filtered)
} , [selectedGame ,selectedMode,selectedCompletion ])
  // const handleApplyGameFilter = () => {
  //   setPage(0)
  //   let filtered = rows
  //   if (selectedGame != 'all') {
  //     filtered = filtered.filter((row) => row.category == selectedGame)
  //   }
  //   if (selectedMode !== 'all') {
  //     filtered = filtered.filter((row) => row.solo == selectedMode)
  //   }

  //   if (selectedCompletion !== 'all') {
  //     filtered = filtered.filter((row) => row.finished == selectedCompletion)
  //   }
  //   setFilteredData(filtered)
  // }
  const handleResetGameFilter = () => {
    setSelectedGame('all')
    setSelectedMode('all')
    setSelectedCompletion('all')
    setFilteredData(rows)
  }

  const handleViewSelectedResult = (data) => {
    // if (resultLoading) {
    //   return
    // }
    const selectedResult = data?.results
    // const selectedResult = resultData?.games?.find((result) => result?._id === data?._id)
    const mainResultData = {
      // ...selectedResult,
      numCorrectGuesses: data?.numCorrectGuesses,
      numGuesses: data?.numGuesses,
      results: data?.results,
      _id: data?._id
    }

    setSelectedGameResult(mainResultData)
    setSelectedGameResultModel(true)
  }
  const handleResultModelClose = () => {
    setSelectedGameResult({})
    setSelectedGameResultModel(false)
  }

  if (isLoading) {
    return (
      <>
        <div className="loaderSection">
          <div className="wrapperloader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      {/* <CModal size="lg" alignment="center" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader style={{ width: '100%' }}>
          <h4>{state.username + "'"}s Games Count</h4>
        </CModalHeader>
        {
          <div className="zoundslike_game_all_popup_voucher_setting_syn">
            <div className="zoundslike_user_bar_chart_from_date_and_to_date_filter_syn">
              <div className="zoundslike_user_bar_chart_from_date_and_to_date_syn">
                <div
                  ref={wrapperRef}
                  onClick={() => handleStartDateClick('fromDate')}
                  className="zoundslike_user_bar_chart_date_filter_syn"
                >
                  From: {formatedDate}
                </div>
                {isClickedFromDate && (
                  <div className="user-dropdown-calender-container">
                    <Calendar
                      maxDate={new Date()}
                      calendarType="US"
                      locale="en"
                      onChange={setSelectedFilterDate}
                      value={selectedFilterDate}
                      formatShortWeekday={(locale, date) => {
                        const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                        return days[date.getDay()]
                      }}
                      formatMonthYear={(locale, date) => {
                        const options = { month: 'short', year: 'numeric' }
                        return new Intl.DateTimeFormat(locale, options).format(date)
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                style={{ marginLeft: '10px' }}
                className="zoundslike_user_bar_chart_from_date_and_to_date_syn"
              >
                <div
                  ref={wrapperRef}
                  onClick={() => handleStartDateClick('toDate')}
                  className="zoundslike_user_bar_chart_date_filter_syn"
                >
                  To: {formatedToDate}
                </div>
                {isClickedToDate && (
                  <div className="user-dropdown-calender-container">
                    <Calendar
                      maxDate={new Date()}
                      calendarType="US"
                      locale="en"
                      onChange={setSelectedToFilterDate}
                      value={selectedToFilterDate}
                      formatShortWeekday={(locale, date) => {
                        const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                        return days[date.getDay()]
                      }}
                      formatMonthYear={(locale, date) => {
                        const options = { month: 'short', year: 'numeric' }
                        return new Intl.DateTimeFormat(locale, options).format(date)
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                style={{ marginLeft: '10px' }}
                className="zoundslike_user_bar_chart_from_sdate_and_to_date_syn"
              >
                <button
                  style={{ background: '#175676', color: 'white', padding: '0px 20px', border: 'none', borderRadius: '4px' }}
                  onClick={handleApplyDateFilter}
                >
                  Apply Date Filter
                </button>
              </div>
            </div>
            <div className="zoundslike_bar_chart_from_date_and_to_date_syn">
              <div>Duration : </div>
              <select
                onChange={handleVarChange}
                value={selectedVar}
                className="user_daily_week_month_year_filter_syn"
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>
            <div className="zoundslike_user_game_date_filter_with_table_setting_syn">
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }}>ID</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Date</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Count</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Solo</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Partnered</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userGameCountFilteredValue !== null &&
                        userGameCountFilteredValue.map((item, i) => {
                          return (
                            <TableRow hover role="checkbox" onClick={() => handleGameCountClick(item)} key={i}>
                              <TableCell style={{ textAlign: 'center' }}>{i + 1}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>{item.date}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>{item.count}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>{item.soloCount}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>{item.partneredCount}</TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                  <div className="pt-2"></div>
                </TableContainer>
               
              </Paper>
            </div>
          </div>
        }
        <CModalFooter>
         
          <CButton onClick={() => setVisible(false)} color="primary">
            Close
          </CButton>
        </CModalFooter>
      </CModal> */}

      {userGamesCount !== null && (
        <CModal
          size="lg"
          alignment="center"
          visible={visibleTwo}
          onClose={() => setVisibleTwo(false)}
        >
          <div>jds </div>
          {/* <CModalHeader style={{ width: '100%' }}>
          <h4>{state.username + "'"}s Games Count</h4>
        </CModalHeader> */}
          {
            <div className="zoundslike_game_all_popup_voucher_setting_syn">
              <div className="zoundslike_user_game_date_filter_with_table_setting_syn">
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: 'center' }}>ID</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Date</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Category</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Game</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Status</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Game Timer(s)</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Played Time(s)</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Guesses</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Correct Guesses</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userGamesCount !== null &&
                          userGamesCount.map((item, i) => {
                            return (
                              <TableRow hover role="checkbox" key={i}>
                                <TableCell style={{ textAlign: 'center' }}>{i + 1}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {`${('0' + new Date(item.created).getUTCDate()).slice(-2)}/${(
                                    '0' +
                                    (new Date(item.created).getUTCMonth() + 1)
                                  ).slice(-2)}/${new Date(item.created).getUTCFullYear()}`}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.category}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{item.solo}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.finished}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.difficulty}
                                </TableCell>
                                {/* <TableCell style={{ textAlign: 'center' }}>
                                  {item.gameTimer}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.playedTime}
                                </TableCell> */}
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.numGuesses}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.numCorrectGuesses}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                    <div className="pt-2"></div>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          }
          <CModalFooter>
            <CButton onClick={() => setVisibleTwo(false)} color="primary">
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}

      <div className="user_container">
        <CRow>
          <h2 className="text-center text-black mt-5">Result</h2>
          <CCol sm={6} lg={3} className="box-title" title="Total words">
            <CWidgetStatsA
              className="mb-4 title"
              color="info"
              value={<>{guessSum}</>}
              title="Total Word"
            />
          </CCol>
          <CCol sm={6} lg={3} className="box-title" title="Total correct words">
            <CWidgetStatsA
              className="mb-4 title"
              color="info"
              value={<>{correctGuessSum}</>}
              title="Total Correct Words"
            />
          </CCol>
          <CCol sm={6} lg={3} className="box-title" title="Total correct words">
            <CWidgetStatsA
              className="mb-4 title"
              color="info"
              value={<>{userAvg}%</>}
              title="Average Accuracy"
            />
          </CCol>
          <CCol
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {isLoading === false && result.length !== 0 && visible === false ? (
              <div
                onClick={() => setVisible(true)}
                className="zoundslike_user_list_button_for_count_syn"
              >
                Games Count
              </div>
            ) : (
              isLoading === false && (
                <div
                  onClick={() => setVisible(false)}
                  className="zoundslike_user_list_button_for_count_syn"
                >
                  Result
                </div>
              )
            )}
          </CCol>
        </CRow>

        {isLoading === true ? (
          <div className="zoundslike_loading_syn"></div>
        ) : (
          <>
            <h4 style={{ marginBottom: setVisible ? '20px' : '0px' }}>
              {state.username + "'"}s games
            </h4>
            {result.length !== 0 ? (
              <>
                {visible === false ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '15px',
                        marginTop: '20px',
                        gap: '20px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div className="user_difficulty_select_box">
                        <select
                          value={selectedGame}
                          onChange={(e) => setSelectedGame(e.target.value)}
                          style={{ backgroundColor: '#fff' }}
                        >
                          <option value="all">Category</option>
                          <option value="custom">Custom AI</option>
                          <option value="amounts">Amounts</option>
                          <option value="anatomy">Anatomy</option>
                          <option value="animals">Animals</option>
                          <option value="architecture">Architecture</option>
                          <option value="attire">Attire</option>
                          <option value="dining">Dining</option>
                          <option value="feelings">Feelings</option>
                          <option value="health">Health</option>
                          <option value="home">Home</option>
                          <option value="landscapes">Landscapes</option>
                          <option value="law">Law</option>
                          <option value="people">People</option>
                          <option value="random">Random</option>
                          <option value="soundalikes">Soundalikes</option>
                          <option value="sports">Sports</option>
                          <option value="tech">Tech</option>
                          <option value="travel">Travel</option>
                          <option value="work">Work</option>
                        </select>
                      </div>
                      <div className="user_difficulty_select_box">
                        <select
                          value={selectedCompletion}
                          onChange={(e) => setSelectedCompletion(e.target.value)}
                          style={{ backgroundColor: '#fff' }}
                        >
                          <option value="all">Status</option>
                          <option value="Completed">Completed</option>
                          <option value="Not Completed">Not Completed</option>
                        </select>
                      </div>
                      <div className="user_difficulty_select_box">
                        <select
                          value={selectedMode}
                          onChange={(e) => setSelectedMode(e.target.value)}
                          style={{ backgroundColor: '#fff' }}
                        >
                          <option value="all">Mode</option>
                          <option value="Solo">Solo</option>
                          <option value="Partnered">Partnered</option>
                        </select>
                      </div>
                      {/* <div>
                        <button
                          style={{
                            background: '#175676',
                            color: 'white',
                            padding: '8px 20px',
                            border: 'none',
                            borderRadius: '4px',
                          }}
                          onClick={handleApplyGameFilter}
                        >
                          Apply Filters
                        </button>
                      </div> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      {rows?.length !== filteredData.length && (
                        <div>
                          <button
                            style={{
                              background: '#175676',
                              color: 'white',
                              padding: '8px 20px',
                              border: 'none',
                              borderRadius: '4px',
                            }}
                            onClick={handleResetGameFilter}
                          >
                            Reset Filters
                          </button>
                        </div>
                      )}
                    </div>
                    <p style={{ margin: '0', padding: '10px 0' }}>Results: {filteredData.length}</p>
                    <Paper
                      sx={{
                        width: '100%',
                        overflow: 'hidden',
                        backgroundColor: 'white',
                        margin: '20px 0',
                      }}
                    >
                      <TableContainer sx={{ maxHeight: 440 }}>
                        {filteredData.length > 0 ? (
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ textAlign: 'center' }}>ID</TableCell>
                                {/* <TableCell>Username</TableCell>
                          <TableCell>Email</TableCell> */}
                                <TableCell style={{ textAlign: 'left' }}>Topic</TableCell>
                                {/* <TableCell style={{ textAlign: 'center' }}>Game Timer(s)</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  Played Time(s)
                                </TableCell> */}
                                <TableCell style={{ textAlign: 'center', width: '130px' }}>
                                  Played On
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Status</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Difficulty</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Mode</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Guesses</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  Correct Guesses
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  Incorrect Guesses
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}></TableCell>
                                {/* {columns.map((column, index) => (
                            <TableCell key={index} align="left">
                              {column.label}
                            </TableCell>
                          ))} */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                    // onClick={() => showResult(row)}
                                  >
                                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                    {columns.map((column, colIndex) => {
                                      const value = row[column.id]
                                      const topicName = row?.topicofCustom
                                      if (column.id === 'finished') {
                                        return value === 'Completed' ? (
                                          <TableCell
                                            style={{ color: 'green', textAlign: 'center' }}
                                            key={colIndex}
                                            align="left"
                                          >
                                            {value}
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            style={{ color: 'red', textAlign: 'center' }}
                                            key={colIndex}
                                            align="left"
                                          >
                                            {value}
                                          </TableCell>
                                        )
                                      }
                                      if (column.id === 'category') {
                                        return value === 'custom' ? (
                                          <TableCell key={colIndex} align="left">
                                            {value}
                                            {topicName}
                                          </TableCell>
                                        ) : (
                                          <TableCell key={colIndex} align="left">
                                            {value}
                                          </TableCell>
                                        )
                                      }
                                      return (
                                        <TableCell
                                          style={{ textAlign: 'center' }}
                                          key={colIndex}
                                          align="left"
                                        >
                                          {value}
                                        </TableCell>
                                      )
                                    })}
                                    <TableCell style={{ textAlign: 'center' }} align="left">
                                      <button
                                        style={{
                                          background: '#175676',
                                          color: 'white',
                                          padding: '8px 20px',
                                          border: 'none',
                                          borderRadius: '4px',
                                        }}
                                        onClick={() => {
                                          handleViewSelectedResult(row)
                                        }}
                                      >
                                        View
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <div style={{ padding: '20px 0' }}>
                            <p style={{ textAlign: 'center', margin: '0' }}>
                              No data matches the selected filters.
                            </p>
                          </div>
                        )}
                        <div className="pt-2"></div>
                      </TableContainer>
                      <TablePagination
                        //  rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        isLoading={isLoading}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      {result.length === 0 && <h2>Result is not available</h2>}
                    </Paper>
                  </>
                ) : (
                  <div style={{ marginBottom: '20px' }}>
                    {/* <h2 style={{ width: '100%' }}> */}
                    {/* <h4>{state.username + "'"}s Games Count</h4> */}
                    {/* </h2> */}
                    {
                      <div className="zoundslike_game_all_popup_voucher_setting_syn">
                        {/* <CCol> */}
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '15px',
                            marginTop: '20px',
                            marginLeft: '10px',
                          }}
                        >
                          {/* <div className="zoundslike_user_bar_chart_from_date_and_to_date_filter_syn">
                            <div className="zoundslike_user_bar_chart_from_date_and_to_date_syn">
                              <div
                                ref={wrapperRef}
                                onClick={() => handleStartDateClick('fromDate')}
                                className="zoundslike_user_bar_chart_date_filter_syn"
                              >
                                From: {formatedDate}
                              </div>
                              {isClickedFromDate && (
                                <div className="user-dropdown-calender-container">
                                  <Calendar
                                    maxDate={new Date()}
                                    calendarType="US"
                                    locale="en"
                                    onChange={setSelectedFilterDate}
                                    value={selectedFilterDate}
                                    formatShortWeekday={(locale, date) => {
                                      const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                                      return days[date.getDay()]
                                    }}
                                    formatMonthYear={(locale, date) => {
                                      const options = { month: 'short', year: 'numeric' }
                                      return new Intl.DateTimeFormat(locale, options).format(date)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              style={{ marginLeft: '10px' }}
                              className="zoundslike_user_bar_chart_from_date_and_to_date_syn"
                            >
                              <div
                                ref={wrapperRef}
                                onClick={() => handleStartDateClick('toDate')}
                                className="zoundslike_user_bar_chart_date_filter_syn"
                              >
                                To: {formatedToDate}
                              </div>
                              {isClickedToDate && (
                                <div className="user-dropdown-calender-container">
                                  <Calendar
                                    maxDate={new Date()}
                                    calendarType="US"
                                    locale="en"
                                    onChange={setSelectedToFilterDate}
                                    value={selectedToFilterDate}
                                    formatShortWeekday={(locale, date) => {
                                      const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                                      return days[date.getDay()]
                                    }}
                                    formatMonthYear={(locale, date) => {
                                      const options = { month: 'short', year: 'numeric' }
                                      return new Intl.DateTimeFormat(locale, options).format(date)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              style={{ marginLeft: '10px' }}
                              className="zoundslike_user_bar_chart_from_sdate_and_to_date_syn"
                            >
                              <button
                                style={{ background: '#175676', color: 'white', padding: '0px 20px', border: 'none', borderRadius: '4px' }}
                                onClick={handleApplyDateFilter}
                              >
                                Apply Date Filter
                              </button>
                            </div>
                          </div> */}
                          <div className="zoundslike_bar_chart_from_date_and_to_date_syn">
                            <div>Duration : </div>
                            <select
                              onChange={handleVarChange}
                              value={selectedVar}
                              className="user_daily_week_month_year_filter_syn"
                            >
                              <option value="Daily">Daily</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Monthly">Monthly</option>
                            </select>
                          </div>
                        </div>
                        {/* </CCol> */}
                        <div className="zoundslike_user_game_date_filter_with_table_setting_syn">
                          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ textAlign: 'center' }}>ID</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Date</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Count</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Solo</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Partnered</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {userGameCountFilteredValue !== null &&
                                    userGameCountFilteredValue
                                      .slice(
                                        pageTwo * rowsPerPage,
                                        pageTwo * rowsPerPage + rowsPerPage,
                                      )
                                      .map((item, i) => {
                                        return (
                                          <TableRow hover role="checkbox" key={i}>
                                            {/* onClick={() => handleGameCountClick(item)} */}
                                            <TableCell style={{ textAlign: 'center' }}>
                                              {i + 1 + pageTwo * rowsPerPage}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              {item.date}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              {item.count}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              {item.soloCount}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                              {item.partneredCount}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                </TableBody>
                              </Table>
                              <div className="pt-2"></div>
                            </TableContainer>
                            <TablePagination
                              //  rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={userGameCountFilteredValue.length}
                              rowsPerPage={rowsPerPage}
                              page={pageTwo}
                              onPageChange={handleChangePageTwo}
                              isLoading={isLoading}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            {userGameCountFilteredValue.length === 0 && (
                              <h2>Result is not available</h2>
                            )}
                          </Paper>
                        </div>
                      </div>
                    }
                    {/* <CModalFooter> */}
                    {/* <CButton color="secondary" onClick={() => setVisible(false)}>
                      Cancel
                    </CButton> */}
                    {/* <CButton onClick={() => setVisible(false)} color="primary">
                        Close
                      </CButton> */}
                    {/* </CModalFooter> */}
                  </div>
                )}
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '90vh' }}
              >
                <h2>Result is not available</h2>
              </div>
            )}
          </>
        )}

        {showModal ? (
          <div className="model-div delete-popup" onClick={() => setShowModal(false)}>
            <div
              className={
                showmsg === false
                  ? 'modal orders-model'
                  : 'modal orders-model zoundslike_result_dis_grid'
              }
            >
              <div className="table-overflow">
                <>
                  <CTable id="table-to-xls" className="users-table orders-table">
                    {showmsg === false && (
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell scope="col" className="table-head-th">
                            No
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="table-head-th">
                            Words
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="table-head-th">
                            Guess words
                          </CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                    )}
                    <CTableBody>
                      {gameResult &&
                        gameResult.map((user, i) => {
                          let wordValue
                          let guessValue
                          if (Array.isArray(user.word)) {
                            wordValue = user.word.word
                            guessValue = user.guess.guess
                          } else if (typeof user.word === 'object') {
                            wordValue = user.word.word
                            guessValue = user.guess
                          } else {
                            wordValue = user.word
                            guessValue = user.guess
                          }

                          return (
                            <>
                              <CTableRow key={i}>
                                <CTableDataCell>{i + 1}</CTableDataCell>
                                {user.word === undefined || user.word === '' ? (
                                  <CTableDataCell>
                                    {<p className="text-danger">Not available</p>}
                                  </CTableDataCell>
                                ) : (
                                  <CTableDataCell>{wordValue}</CTableDataCell>
                                )}
                                {user.guess === undefined || user.guess === '' ? (
                                  <CTableDataCell>
                                    {<p className="text-danger">Not available</p>}
                                  </CTableDataCell>
                                ) : (
                                  <CTableDataCell>{guessValue}</CTableDataCell>
                                )}
                              </CTableRow>
                            </>
                          )
                        })}
                    </CTableBody>
                  </CTable>
                </>
                {showmsg === true && <h2>Result is not available</h2>}
                {/* } */}
              </div>
              <div className="model-popup-btn-group">
                <button className="top-button cancel-btn" onClick={() => setShowModal(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <CModal
        size="lg"
        visible={selectedGameResultModel}
        scrollable
        aria-labelledby="ScrollingLongContentExampleLabel2"
        onClose={() => handleResultModelClose()}
      >
        {selectedGameResult?.results && selectedGameResult?.results?.length > 0 ? (
          <CModalHeader>
            <CRow className="justify-content-between w-100">
              <CCol md={6}>
                <h3 className="m-0">Game Results</h3>
              </CCol>
              <CCol md={4}>
                {selectedGameResult?.numGuesses > 0 && (
                  <h3 className="m-0">
                    {(
                      (selectedGameResult?.numCorrectGuesses / selectedGameResult?.numGuesses) *
                      100
                    ).toFixed(0)}
                    % ({selectedGameResult?.numCorrectGuesses}/{selectedGameResult?.numGuesses})
                  </h3>
                )}
              </CCol>
            </CRow>
          </CModalHeader>
        ) : (
          <CModalHeader>No Result Found.</CModalHeader>
        )}
        {selectedGameResult?.results && selectedGameResult?.results?.length > 0 && (
          <CModalBody>
            <div className="game-result-table">
              <CTable hover responsive striped>
                <CTableHead>
                  <CTableRow color="dark">
                    <CTableHeaderCell scope="col" style={{ textAlign: 'center' }}>
                      #
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" style={{ textAlign: 'center', width: '150px' }}>
                      Actual Word
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" style={{ textAlign: 'center', width: '150px' }}>
                      Guessed Word
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" style={{ textAlign: 'center' }}>
                      Sentence
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {selectedGameResult?.results?.map((item, index) => (
                    <CTableRow key={index}>
                      <CTableDataCell style={{ textAlign: 'center' }}>{index + 1}</CTableDataCell>
                      <CTableDataCell style={{ textAlign: 'center' }}>
                        {item?.word?.word}
                      </CTableDataCell>
                      <CTableDataCell
                        color={item.correct ? 'success' : 'danger'}
                        style={{ textAlign: 'center' }}
                      >
                        {item.guess}
                      </CTableDataCell>
                      <CTableDataCell style={{ textAlign: 'center' }}>
                        {item.word.sentence}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </div>
          </CModalBody>
        )}
      </CModal>
    </>
  )
}

export default User
