import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const category = [
    {
        name: 'Soundalikes',
        value: 'category_soundalikes',
        color: '#ff45ed'
    },
    {
        name: 'Amounts',
        value: 'category_amounts',
        color: '#3e48ff'
    },
    {
        name: 'Anatomy',
        value: 'category_anatomy',
        color: '#00aaff'
    },
    {
        name: 'Animals',
        value: 'category_animals',
        color: '#39b70f'
    },
    {
        name: 'Architecture',
        value: 'category_architecture',
        color: '#ff4daf'
    },
    {
        name: 'Attire',
        value: 'category_attire',
        color: '#ff3e3e'
    },
    {
        name: 'Landscapes',
        value: 'category_landscapes',
        color: '#36ce36'
    },
    {
        name: 'Random',
        icon: 'category_random',
        value: undefined,
        color: '#b20059'
    },
    {
        name: 'Dining',
        value: 'category_dining',
        color: '#efd203'
    },
    {
        name: 'Feelings',
        value: 'category_feelings',
        color: '#00baa3'
    },
    {
        name: 'Health',
        value: 'category_health',
        color: '#633eff'
    },
    {
        name: 'Home',
        value: 'category_home',
        color: '#ff5027',
        disabled: true
    },
    {
        name: 'Law',
        value: 'category_law',
        color: '#a9d100',
        disabled: true
    },
    {
        name: 'People',
        value: 'category_people',
        color: '#1569ff',
        disabled: true
    },
    {
        name: 'Sports',
        value: 'category_sports',
        color: '#ff6333',
        disabled: true
    },
    {
        name: 'Tech',
        value: 'category_tech',
        color: '#009fef',
        disabled: true
    },
    {
        name: 'Travel',
        value: 'category_travel',
        color: '#b331ff',
        disabled: true
    },
    {
        name: 'Work',
        value: 'category_work',
        color: '#ff9d00',
        disabled: true
    }
]

export const fetchWordsData = createAsyncThunk(
    'words/fetchUserData',
    async () => {
        try {
            const response = await axios.get(baseURL + '/v1/all-words');
            return response.data;
        } catch (error) {
            console.error('API Error:', error);
            throw error;
        }
    }
);


const allWordState = {
    allWordsData: null,
    count: null,
    loading: false,
    error: null,
}


export const allWordsSlice = createSlice({
    name: 'words',
    initialState: allWordState,
    reducers: {
        addWordLocally: (state, action) => {
            const newWord = action.payload;

            if (state.allWordsData && typeof state.allWordsData === 'object') {
                if (!state.allWordsData.words) {
                    state.allWordsData.words = [];
                }

                state.allWordsData.words.push(newWord);

                state.count = state.allWordsData.words.length;
            }
        },
        removeWordLocally: (state, action) => {
            const wordToRemove = action.payload;
            if (state.allWordsData && typeof state.allWordsData === 'object') {
                if (state.allWordsData.words) {
                    const updatedState = state.allWordsData.words.filter(
                        (word) => word.word !== wordToRemove
                    );

                    state.allWordsData.words = updatedState

                    state.count = state.allWordsData.words.length;
                }
            }
        },
        updateWordLocally: (state, action) => {
            const updatedData = action.payload;
            // console.log('updatedData', updatedData);
            if (state.allWordsData && typeof state.allWordsData === 'object') {
                if (state.allWordsData.words) {
                    state.allWordsData.words = state.allWordsData.words.map((word) => {
                        if (word._id === updatedData._id) {
                            return {
                                ...word,
                                word: updatedData.word,
                                sentence: updatedData.sentence,
                                difficulty: updatedData.difficulty
                            };
                        }
                        return word;
                    });
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWordsData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWordsData.fulfilled, (state, action) => {
                state.loading = false;
                state.allWordsData = action.payload;
                state.count = action.payload.words.length
            })
            .addCase(fetchWordsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
})

export const { addWordLocally, removeWordLocally, updateWordLocally } = allWordsSlice.actions

export default allWordsSlice.reducer