import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './scss/style.scss';
import { hasCookie } from 'cookies-next';
import Login from './components/login/login';
import Register from './views/pages/register/Register';
import DefaultLayout from './layout/DefaultLayout';
import UserLogout from './components/logout/logout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from './store/AllUsers';
import { fetchMaxCatagoryGamePlayedData } from './store/MaxCatagory';
import { fetchWordsData } from './store/AllWords';
import { fetchWeeklyGamesData } from './store/GetGameMonthData';
import { fetchMonthlyGamesData } from './store/GetGameMonthData';
import { fetchYearlyGamesData } from './store/GetGameMonthData';

const App = () => {
  const auth = hasCookie("Token");
  const navigate = useNavigate();
  const currentURL = window.location.href;
  console.log(currentURL);
  console.log('app.js rul', currentURL);
  const dispatch = useDispatch()

  const playedGameMaxCatagory = useSelector((state) => state.allGames.maxGamePlayedInCatagoryWithCount)

  useEffect(() => {
    if (auth) {
      dispatch(fetchWeeklyGamesData())
      dispatch(fetchMonthlyGamesData())
      dispatch(fetchYearlyGamesData())
      dispatch(fetchData())
      dispatch(fetchWordsData())
    }
  }, [dispatch, auth])

  useEffect(() => {
    if (playedGameMaxCatagory.catagory !== '') {
      const formattedCatagory = {
        catagory: `${playedGameMaxCatagory.catagory.toLowerCase()}`
      };
      dispatch(fetchMaxCatagoryGamePlayedData(formattedCatagory));
    }
  }, [playedGameMaxCatagory])

  useEffect(() => {
    if (!auth) {
      console.log('app.js 41');
      navigate('/login');
    } else {
      // navigate('/dashboard');
    }
  }, []);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  return (
    <DefaultLayout />,
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/logout" name="Logout Page" element={<UserLogout />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        {auth && <Route path="*" name="Home" element={<DefaultLayout />} />}
      </Routes>
    </Suspense>
  );
};

export default App;
