import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
const baseURL = process.env.REACT_APP_URL
export let UserApiCall = createApi({
  reducerPath: 'UserApiCall',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => ({
        url: '/v1/all-users',
        method: 'GET',
      }),
    }),

    getUsers: builder.query({
      query: ({ page, perPage }) => {
        // console.log('Fetching page:', page, perPage)
        return {
          url: `/v1/users?page=${page}&perPage=${perPage}`,
          method: 'GET',
          params: {
            page: page,
            perPage: perPage,
            pagination: true,
          },
        }
      },
    }),

    //**** Get all the games ****//
    getGames: builder.query({
        query: (payload) => {
            return {
                url: `/v1/user-games`,
                method: 'POST',
                body: payload,
            };
        },
    }),

    //**** Get results of users ****//
    getResults: builder.query({
        query: () => {
            return {
                url: `/v1/game-results`,
                method: 'GET',
            };
        },
    }),

    //**** Get stats of custom ai category ****//
    getCustomStats: builder.query({
        query: () => {
            return {
              url: `/v1/category_custom/stats`,
              method: 'GET',
            }
        },
    }),
    
    //**** Get username by specific category of custom ai category ****//
    getCustomAiCategoryWiseUsername: builder.query({
        query: ({prompt}) => {
            return {
              url: `/v1/category_custom/categorywise_username?prompt=${prompt}`,
              method: 'GET',
            }
        },
    }),

    getDashbordGames: builder.query({
      query: () => {
        return {
          url: '/v1/all-games',
          method: 'GET',
        }
      },
      transformResponse: (response) => response.users,
    }),
  }),
})

export let { useGetAllUsersQuery,useGetGamesQuery,useGetUsersQuery,useGetDashbordGamesQuery,useGetResultsQuery, useGetCustomStatsQuery, useGetCustomAiCategoryWiseUsernameQuery } = UserApiCall
